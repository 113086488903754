/**
 * phoneFormat
 * @param {string} phone
 * @return {string} formatted phone like (00) 0000-0000 or (00) 00000-0000
 */
const phoneFormat = (
	phone: string | number | null | undefined,
): string | null | undefined => {
	if (phone === null || phone === undefined) {
		return phone;
	}
	const str = `${phone}`;
	const onlyNums = str.replace(/[^\d]/g, '');
	if (onlyNums.length <= 2) {
		return onlyNums;
	}
	if (onlyNums.length <= 6) {
		return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}`;
	}
	if (onlyNums.length <= 10) {
		return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(
			2,
			6,
		)}-${onlyNums.slice(6, 11)}`;
	}
	return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
		7,
		11,
	)}`;
};

export default phoneFormat;
