import React from 'react';
import { signOut } from 'next-auth/react';
import { useField } from 'react-final-form';
import memoizeOne from 'memoize-one';

import Box from '@ui/components/Box';
import Text from '@ui/components/Text';
import { styled } from '@ui/styles';

import Form from '@common-lib/components/form/AsyncForm';
import SubmitButton from '@common-lib/components/form/SubmitButton';
import TextInputField from '@common-lib/components/form/FormInputs/TextInputField';
import phoneParse from '@common-lib/extractors/phoneParse';
import phoneFormat from '@common-lib/format/phoneFormat';

import validationSchema from './validationSchema';

const Input = styled(TextInputField)(() => ({
	'& .MuiOutlinedInput-root': {
		borderRadius: 30,
	},
}));

const initialValues = memoizeOne((email?: string, phoneNumber?: string) => {
	return { email, phoneNumber };
});

const HelpInfo: React.FC = () => {
	const {
		input: { value: email },
	} = useField('email');
	const {
		input: { value: phoneNumber },
	} = useField('phoneNumber');
	if (!email && !phoneNumber) {
		return (
			<Text
				variant="caption"
				textAlign="center"
				color="primary"
				width="100%"
			>
				&nbsp;
			</Text>
		);
	}
	return (
		<Text
			variant="caption"
			textAlign="center"
			color="primary"
			width="100%"
			mt={1.25}
		>
			{email
				? 'Você vai receber un link de login nesse email'
				: 'Você receberá um SMS para verificação de login'}
		</Text>
	);
};
const LoginForm: React.FC<{
	onSubmitSuccess?: () => void;
	onSubmit: (email: string, phoneNumber: string) => Promise<any>;
	SubForm?: React.FC;
	mode: 'check' | 'login';
	email?: string;
	phoneNumber?: string;
}> = ({ onSubmitSuccess, onSubmit, SubForm, mode, email, phoneNumber }) => {
	const handleSubmitSuccess = React.useCallback(() => {
		if (onSubmitSuccess) {
			onSubmitSuccess();
		}
	}, [onSubmitSuccess]);
	const handleSubmit = React.useCallback(
		(input: any) => {
			// force signout existing
			return signOut({ redirect: false }).finally(() =>
				onSubmit(input.email, input.phoneNumber).then(() => {
					// workarround to avoid form mutation not return error
					return true;
				}),
			);
		},
		[onSubmit],
	);
	return (
		<Form
			onSubmit={handleSubmit}
			validateOnBlur
			name="login-form"
			initialValues={initialValues(email, phoneNumber)}
			onSubmitSuccess={handleSubmitSuccess}
			validationSchema={validationSchema()}
		>
			{() => {
				return (
					<>
						<Input
							fullWidth
							name="email"
							type="email"
							placeholder="E-mail"
							color="primary"
						/>
						<Text pt={1.25} textAlign="center">
							ou
						</Text>
						<Input
							fullWidth
							name="phoneNumber"
							parse={phoneParse}
							format={phoneFormat}
							placeholder="Fone"
							color="primary"
						/>

						<Box
							display="flex"
							justifyContent="center"
							width="100%"
							flexDirection="column"
						>
							<SubmitButton
								pt={2}
								pb={0.1}
								fullWidth
								disableOnSubmitSuccess={false}
								color="primary"
								className="login"
							>
								{mode === 'check' ? 'Cadastrar' : 'Login'}
							</SubmitButton>
							{mode === 'login' ? <HelpInfo /> : null}
						</Box>

						{SubForm ? <SubForm /> : null}
					</>
				);
			}}
		</Form>
	);
};

export default LoginForm;
