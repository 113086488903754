import React from 'react';

import Button from '@ui/components/Button';
import Text from '@ui/components/Text';
import Box, { BoxProps } from '@ui/components/Box';
// import LinkText from '@ui/components/LinkText';

import { hashCode } from '@common-lib/helpers/stringHelper';

type Props = {
	message?: string;
	onRetry?: () => void;
	hideErrorIcon?: boolean;
} & Omit<BoxProps, 'ref'>;
const LoadingError: React.FC<Props> = ({
	message,
	onRetry,
	hideErrorIcon,
	...props
}) => {
	const hash = hashCode(`${message || ''}${new Date().toISOString()}`);
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
			p={2}
			width="100%"
			height="100%"
			mt={8}
			{...props}
		>
			{hideErrorIcon ? null : (
				<Box pb={2}>
					<img src="/images/error-500.svg" alt="error" />
				</Box>
			)}
			{message || 'Problema carregando data'}
			{/* <LinkText to="/help">
				<Text variant="h6" fontWeight="bold" color="primary">
					Central de Ajuda
				</Text>
			</LinkText> */}
			<Text variant="body1" fontWeight="medium" textAlign="center">
				Identificador do erro: {hash}
			</Text>
			<Text variant="body1" gutterBottom textAlign="center">
				Use isso na sua comunicação com a Central de Ajuda
			</Text>
			<Box pb={2} pt={2} display="flex" textAlign="center">
				{onRetry ? (
					<Button
						variant="outlined"
						color="primary"
						onClick={onRetry}
						size="medium"
					>
						Retentar
					</Button>
				) : null}
			</Box>
		</Box>
	);
};
LoadingError.defaultProps = {
	message: undefined,
	onRetry: undefined,
};
export default React.memo(LoadingError);
