import React from 'react';

import Google from '@ui/icons/Google';
import { IconButton, IconButtonProps } from '@ui/components';
import { styled } from '@ui/styles';

const Button = styled(IconButton)(({ theme }) => ({
	background: theme.palette.primary.lighter,
	'&:hover': {
		background: theme.palette.primary.light,
	},
}));

const GmailButton = (props: IconButtonProps) => (
	<Button size="small" aria-label="Login com Google" {...props}>
		<Google height={22} width={22} />
	</Button>
);

export default GmailButton;
