import React from 'react';
import { useRouter } from 'next/router';

import Box, { BoxProps } from '@ui/components/Box';
import Text from '@ui/components/Text';
import { styled } from '@ui/styles';
import { Row, Col, Button, Image, Link } from '@ui/components';
import { Scope } from '@ui/icons';

import MobileContext from '@web/components/MobileContext';

import LoginForm from '@common-lib/modules/account/forms/LoginForm';
import GmailButton from '@common-lib/modules/account/components/GmailButton';
import Background from '@common-lib/modules/account/components/Background';
import ContentBox from '@common-lib/components/Content/ContentBox';
import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import HomeUpcomingEvent from '@common-lib/modules/event/components/HomeUpcomingEvent';
import clientConfig from '@common-lib/clientConfig';

// import FacebookButton from '@common-lib/modules/account/components/FacebookButton';
const {
	ongoingEvent: { id: ongoingEventId },
} = clientConfig;

export type LoginProps = {
	onSubmitSuccess?: () => void;
	onSocialLogin: (provider: 'FACEBOOK' | 'GOOGLE') => void;
	onEmailLogin: (email: string, isAcceptedTerms?: boolean) => Promise<any>;
	onPhoneLogin: (
		phoneNumber: string,
		isAcceptedTerms?: boolean,
	) => Promise<any>;
	redirectUrl?: string;
} & Omit<BoxProps, 'ref' | 'children'>;

const Wrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	height: '100vh',
	display: 'flex',
	flexDirection: 'row',
	[theme.breakpoints.up('sm')]: {
		justifyContent: 'center',
	},
}));
const WrapperLeft = styled(Box)(({ theme }) => ({
	position: 'relative',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	[theme.breakpoints.up('lg')]: {
		paddingLeft: theme.spacing(10),
	},
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(15),
	},
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(5),
	},
}));
const WrapperRight = styled(Box)(({ theme }) => ({
	position: 'relative',
	height: '100%',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
	width: '100%',
	// overflow: 'hidden',
	'& *': {
		width: '108% !important',
		height: '108% !important',
		position: 'absolute',
		top: -80,
		right: -75,
	},
}));
const FromWrapper = styled(Box)(({ theme }) => ({
	borderRadius: 42,
	background: '#FFFFFF',
	'& .MuiFormHelperText-root': {
		textAlign: 'center',
	},
	width: '100%',
	paddingLeft: theme.spacing(3.5),
	paddingRight: theme.spacing(3.5),
	paddingTop: theme.spacing(4.5),
	paddingBottom: theme.spacing(2),
	[theme.breakpoints.down('sm')]: {
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
		paddingTop: theme.spacing(4.5),
		paddingBottom: theme.spacing(2),
	},
	[theme.breakpoints.up('sm')]: {
		width: 469,
	},
}));
const FromRoot = styled(Box)(({ theme }) => ({
	borderRadius: 42,
	background: '#FFFFFF',
	'& .MuiFormHelperText-root': {
		textAlign: 'center',
	},
	width: '100%',
	paddingLeft: theme.spacing(3.5),
	paddingRight: theme.spacing(3.5),
	paddingTop: theme.spacing(4.5),
	paddingBottom: theme.spacing(2),
	[theme.breakpoints.down('sm')]: {
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
		paddingTop: theme.spacing(4.5),
		paddingBottom: theme.spacing(2),
	},
	[theme.breakpoints.up('sm')]: {
		width: 469,
	},
}));
const LeftContent = styled(Box)(({ theme }) => ({
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		width: 469,
	},
	'& > div': {
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 469,
		},
	},
}));
const RightContent = styled(Box)(({ theme }) => ({
	width: '100%',
}));
const PinkBox = styled(Box)(({ theme }) => ({
	background: theme.palette.warning.light,
	borderRadius: 30,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	flexWrap: 'nowrap',
}));
const BackButton = styled(Button)(({ theme }) => ({
	background: theme.palette.primary.light,
	':hover': {
		background: theme.palette.primary.lighter,
	},
}));
const LinkWrapper = styled(Text)(({ theme }) => ({
	fontFamily: theme.fontFamily.chalet,
	color: theme.palette.info.main,
	'& a': {
		color: theme.palette.info.main,
	},
}));
const A = styled('a')(({ theme }) => ({
	cursor: 'pointer',
}));
const LinkBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	'& a': {
		padding: theme.spacing(1),
	},
}));
const Login: React.FC<LoginProps> = ({
	onSubmitSuccess,
	onSocialLogin,
	onEmailLogin,
	onPhoneLogin,
	redirectUrl,
	...props
}) => {
	const { back, push, query } = useRouter();
	const isMobile = React.useContext(MobileContext);
	const [email, setEmail] = React.useState('');
	const [phoneNumber, setPhoneNumber] = React.useState('');
	const [missing, setMissing] = React.useState(false);
	// const handleFacebookLogin = React.useCallback(() => {
	// 	onSocialLogin('FACEBOOK');
	// }, [onSocialLogin]);
	const error = query?.error;
	React.useEffect(() => {
		if (typeof sessionStorage !== 'undefined') {
			setEmail(sessionStorage.getItem('email') || '');
			setPhoneNumber(sessionStorage.getItem('phoneNumber') || '');
		}
	}, []);
	React.useEffect(() => {
		if (error === 'OAuthCreateAccount') {
			setMissing(true);
		} else {
			setMissing(false);
		}
	}, [error]);
	const handleGoogleLogin = React.useCallback(() => {
		sessionStorage.removeItem('email');
		sessionStorage.removeItem('phoneNumber');
		onSocialLogin('GOOGLE');
	}, [onSocialLogin]);
	const handleback = React.useCallback(() => {
		sessionStorage.removeItem('email');
		sessionStorage.removeItem('phoneNumber');
		back();
	}, [back]);
	const handleSubmit = React.useCallback(
		(email: string, phoneNumber: string) => {
			setMissing(false);
			if (phoneNumber) {
				return onPhoneLogin(phoneNumber).catch(err => {
					if (err.message === 'Missing user') {
						setMissing(true);
					}
					sessionStorage.removeItem('email');
					sessionStorage.removeItem('phoneNumber');
				});
			} else {
				return onEmailLogin(email).catch(err => {
					if (err.message === 'Missing user') {
						setMissing(true);
					}
					sessionStorage.removeItem('email');
					sessionStorage.removeItem('phoneNumber');
				});
			}
		},
		[onEmailLogin],
	);
	const handleClick = React.useCallback(() => {
		if (ongoingEventId) {
			push({
				pathname: '/eventos/[eventId]/cadastro',
				query: { eventId: ongoingEventId },
			});
		} else {
			push({
				pathname: '/auth/register/[[...redirectUrl]]',
				query: {
					redirectUrl: redirectUrl || '/',
				},
			});
		}
	}, [back, redirectUrl]);
	const handleSubmitSuccess = React.useCallback(() => {
		sessionStorage.removeItem('email');
		sessionStorage.removeItem('phoneNumber');
		if (onSubmitSuccess) {
			onSubmitSuccess();
		}
	}, [onSubmitSuccess]);
	return (
		<ContentWrapper pb={isMobile ? 15 : undefined}>
			<ContentBox maxWidth={1332}>
				<Background />
				<Wrapper {...props}>
					<WrapperLeft>
						<LeftContent>
							<Box p={2}>
								<Row
									justifyContent="space-between"
									alignItems="center"
								>
									<Link href="/" passHref legacyBehavior>
										<A>
											<Image
												src="/logo_semina.png"
												alt="logo"
												height={27}
												width={150}
												priority
											/>
										</A>
									</Link>

									<BackButton
										variant="text"
										color="primary"
										size="medium"
										onClick={handleback}
									>
										Voltar
									</BackButton>
								</Row>
							</Box>
							<FromWrapper>
								<HomeUpcomingEvent />
								<Text variant="h3" component="h1" gutterBottom>
									Login
								</Text>
								<LoginForm
									key={`${email}-${phoneNumber}`}
									mode="login"
									onSubmit={handleSubmit}
									email={email}
									phoneNumber={phoneNumber}
									onSubmitSuccess={handleSubmitSuccess}
									SubForm={() => (
										<Row
											justifyContent="center"
											alignItems="center"
											spacing={1}
											pt={2}
										>
											<Col>
												<Text
													variant="body1"
													textAlign="center"
													color="grey"
													fontWeight="700"
												>
													Entrar com
												</Text>
											</Col>
											<Col>
												<GmailButton
													size="large"
													onClick={handleGoogleLogin}
													title="Entrar com Google"
												/>
											</Col>
										</Row>
									)}
								/>
								{missing ? (
									<Row justifyContent="center" pt={1}>
										<Text
											variant="h5"
											component="div"
											ml={2}
											color="error.main"
											textAlign="center"
											fontWeight="bold"
										>
											O usuário não existe. Por favor,
											registre-se antes de fazer login.
										</Text>
									</Row>
								) : null}
								<Row justifyContent="center" pt={1}>
									<Button
										variant="text"
										color="primary"
										size="large"
										fullWidth
										onClick={handleClick}
									>
										Cadastrar
									</Button>
								</Row>
							</FromWrapper>
							<PinkBox mt={2} py={2} px={3.5}>
								<Box>
									<Scope />
								</Box>
								<Text
									variant="h5"
									component="div"
									ml={2}
									color="warning.main"
								>
									Este website é restrito a profissionais de
									medicina. Para acessá-lo, é necessário
									cadastrar-se.
								</Text>
							</PinkBox>
							<LinkWrapper
								variant="h5"
								component="div"
								mt={1}
								textAlign="center"
							>
								<p>
									Não é um profissional de medicina? Acesse
									nossos websites públicos:
								</p>
								<LinkBox>
									<p>
										<Link href="/eventos">Eventos</Link>
									</p>
									<p>
										<Link
											href="https://www.semina.com.br/"
											target="_blank"
										>
											Semina
										</Link>
									</p>
									<p>
										<Link
											href="https://www.kyclinical.com.br/"
											target="_blank"
										>
											K-Y Clinical
										</Link>
									</p>
									<p>
										<Link
											href="https://www.sounuaa.com.br/"
											target="_blank"
										>
											NUÁA
										</Link>
									</p>{' '}
									<p>
										<Link
											href="https://www.amamente.com.br/"
											target="_blank"
										>
											Amamente
										</Link>
									</p>{' '}
								</LinkBox>
							</LinkWrapper>
						</LeftContent>
					</WrapperLeft>
					{isMobile ? null : (
						<WrapperRight>
							<RightContent>
								<Image
									priority
									src="/images/MaskGroupLogin.png"
									alt="login-visual"
									fill
								/>
							</RightContent>
						</WrapperRight>
					)}
				</Wrapper>
			</ContentBox>
		</ContentWrapper>
	);
};

export default Login;
