import React from 'react';
import Head from 'next/head';
import { signIn } from 'next-auth/react';
import { GetServerSideProps } from 'next';
import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';

import { Page } from '@web/types';
import debugPage from '@web/utils/logger';
import PageWrapper from '@web/components/layout/PageWrapper';
import QUERY_USER_EXISTS from '@common-lib/modules/user/graphql/queryUserExists.graphql';
import LoginScreen from '@common-lib/modules/account/screens/Login';
import LoginCheckScreen from '@common-lib/modules/account/screens/LoginCheck';
import { processRouteParams } from '@common-lib/helpers/routerQuery';
import useStatus from '@common-lib/modules/status/hooks';
import getServerSideSession from '@web/utils/getServerSession';
// import { initializeApollo } from '@common-lib/graphql';
import { processUTMsSSR } from '@web/utils/utm';
import Loading from '@common-lib/components/Loading';
import { ssrApolloServer } from '@web/utils/apolloServer';
import MUTATION_SEND_LOGIN_VERIFICATION from '@common-lib/modules/account/graphql/mutationSendLoginVerification.graphql';

const Login: Page<{
	redirectUrl?: string | null;
	callbackUrl: string;
	token?: string | null;
	mode: 'check' | 'login';
}> = ({ redirectUrl, token, callbackUrl, mode }) => {
	const { push } = useRouter();
	const { addError, addSuccess } = useStatus();
	const client = useApolloClient();
	React.useEffect(() => {
		if (token) {
			signIn('credentials', {
				token,
				callbackUrl: redirectUrl || callbackUrl,
				redirect: true,
			}).catch(console.error);
		}
	}, [redirectUrl, token, callbackUrl]);
	const handleSocialLogin = React.useCallback(
		(providerName: 'FACEBOOK' | 'GOOGLE') => {
			signIn(providerName.toLowerCase(), {
				callbackUrl: redirectUrl || callbackUrl,
				redirect: true,
			}).catch(err => {
				addError(
					err.message + 'Cadastre o usuário antes de fazer login',
				);
				console.log(err.message);
			});
		},
		[addError, callbackUrl, redirectUrl],
	);
	const handleEmailLogin = React.useCallback(
		(email: string) => {
			return client
				.query({
					query: QUERY_USER_EXISTS,
					variables: {
						email,
					},
				})
				.then(res => {
					if (res.data?.user_exists?.email) {
						return signIn('email', {
							email,
							callbackUrl: redirectUrl || callbackUrl,
							redirect: true,
						})
							.then(() => {
								addSuccess(
									'Verifique seu email para finalizar login',
								);
							})
							.catch(err => {
								addError(
									'Alguma coisa deu errado. Tenta de novo',
								);
								console.log(err);
							});
					}
					throw new Error('Missing user');
				})
				.catch(err => {
					addError(
						'Parece que não existe. Cadastre o usuário antes de fazer login',
					);
					throw err;
				});
		},
		[addError, addSuccess, callbackUrl, client, redirectUrl],
	);
	const handlePhoneLogin = React.useCallback(
		(phoneNumber: string) => {
			return client
				.mutate<{
					account_sendLoginVerification: {
						vid: string;
						email: string;
					};
				}>({
					mutation: MUTATION_SEND_LOGIN_VERIFICATION,
					variables: {
						phoneNumber,
					},
				})
				.then(res => {
					if (res.data?.account_sendLoginVerification?.vid) {
						if (res.data?.account_sendLoginVerification?.email) {
							sessionStorage.setItem(
								'maskedEmail',
								res.data?.account_sendLoginVerification?.email,
							);
						}
						push({
							pathname: '/auth/login-verify/[[...redirectUrl]]',
							query: {
								redirectUrl: redirectUrl || callbackUrl,
								vid: res.data?.account_sendLoginVerification
									?.vid,
							},
						});
						return true;
					} else {
						throw new Error('Missing user');
					}
				})
				.catch(err => {
					if (err?.message?.includes('Max send')) {
						addError('Máximo de tentativas de envio atingido');
					} else {
						addError('Alguma coisa deu errado. Tenta de novo');
					}
					return false;
				});
		},
		[addError, callbackUrl, client, push, redirectUrl],
	);
	return (
		<>
			<Head>
				<title>Semina: Login</title>
			</Head>
			<PageWrapper px={0} py={0} hideBar>
				{token ? (
					<Loading overlay />
				) : (
					<>
						{mode === 'login' ? (
							<LoginScreen
								onSocialLogin={handleSocialLogin}
								onEmailLogin={handleEmailLogin}
								onPhoneLogin={handlePhoneLogin}
								redirectUrl={redirectUrl || callbackUrl}
							/>
						) : (
							<LoginCheckScreen
								redirectUrl={redirectUrl || callbackUrl}
							/>
						)}
					</>
				)}
			</PageWrapper>
		</>
	);
};

export const getServerSideProps: GetServerSideProps<{
	redirectUrl?: string | null;
	callbackUrl: string;
	token?: string | null;
}> = async context => {
	const {
		redirectUrl = null,
		token = null,
		callbackUrl = '/',
		mode = 'check',
	} = processRouteParams(context.query, [
		'redirectUrl',
		'token',
		'callbackUrl',
		'mode',
	]);
	debugPage(`login page redirectUrl ${redirectUrl}`);
	debugPage(`login page callbackUrl ${callbackUrl}`);
	try {
		// const client = initializeApollo(undefined, { req: context.req });
		const session = await getServerSideSession(context);
		const client = await ssrApolloServer(session);
		await processUTMsSSR(client, context);
		if (session) {
			debugPage(`login page session found`);
			return {
				props: { redirectUrl, token, callbackUrl, mode },
				redirect: {
					permanent: false,
					destination: redirectUrl || callbackUrl,
				},
			};
		}
		debugPage(`login page session not found`);
		return {
			props: { redirectUrl, token, callbackUrl, mode },
		};
	} catch (error) {
		debugPage(error);
	}
	debugPage(`login page session not found`);
	return {
		props: { redirectUrl, token, callbackUrl, mode },
	};
};

export default Login;
