import { object, string } from 'yup';
import memoizeOne from 'memoize-one';

import phone from '@common-lib/validators/phone';

const validationSchema = memoizeOne(() => {
	const required = 'Requerido';
	return object().shape({
		// email: string().required(required).email('Não é um e-mail válido'),
		email: string().nullable().email('Não é um e-mail válido'),
		phoneNumber: string()
			.nullable()
			.test('test-phone', 'Número de telefone inválido', value => {
				if (value) {
					return phone.isValid(value);
				}
				return true;
			})
			.test('required-if-no-email', required, function () {
				const { path, createError, parent } = this;
				if (!parent.email && !parent.phoneNumber) {
					return createError({ path, message: required });
				}
				return true;
			}),
	});
});

export default validationSchema;
