const isValid = (numOrString: string) => {
	if (!numOrString) return false;
	const val = `${numOrString}`.replace(/\D/g, '');
	return val.length === 11 || val.length === 10;
};

const g = {
	isValid,
};

export default g;
