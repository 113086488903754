import React from 'react';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';

import Box, { BoxProps } from '@ui/components/Box';
import Text from '@ui/components/Text';
import { styled } from '@ui/styles';
import { Row, Button, Image, Link } from '@ui/components';
import { Scope } from '@ui/icons';

import MobileContext from '@web/components/MobileContext';

import LoginForm from '@common-lib/modules/account/forms/LoginForm';
import clientConfig from '@common-lib/clientConfig';
import QUERY_USER_EXISTS from '@common-lib/modules/user/graphql/queryUserExists.graphql';
import Background from '@common-lib/modules/account/components/Background';
import ContentBox from '@common-lib/components/Content/ContentBox';
import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import useStatus from '@common-lib/modules/status/hooks';
import HomeUpcomingEvent from '@common-lib/modules/event/components/HomeUpcomingEvent';

// import FacebookButton from '@common-lib/modules/account/components/FacebookButton';

const {
	ongoingEvent: { id: ongoingEventId },
} = clientConfig;
export type LoginCheckProps = {
	redirectUrl?: string;
} & Omit<BoxProps, 'ref' | 'children'>;

const Wrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	height: '100vh',
	display: 'flex',
	flexDirection: 'row',
	[theme.breakpoints.up('sm')]: {
		justifyContent: 'center',
	},
}));
const WrapperLeft = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: '100%',
	// height: '100%',
	// height: '100vh',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	paddingTop: theme.spacing(10),
	[theme.breakpoints.up('lg')]: {
		paddingLeft: theme.spacing(10),
	},
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(10),
	},
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(5),
		'::-webkit-scrollbar': {
			width: 0,
		},
		// paddingTop: 208,
		scrollBehavior: 'smooth',
		scrollbarWidth: 'none',
		touchAction: 'none',
		maxHeight: '100vh',
		overflowY: 'scroll',
		overflowX: 'hidden',
	},
}));
const WrapperRight = styled(Box)(({ theme }) => ({
	position: 'relative',
	height: '100%',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
	width: '100%',
	// overflow: 'hidden',
	'& *': {
		width: '108% !important',
		height: '108% !important',
		position: 'absolute',
		top: -80,
		right: -75,
	},
}));
const FromWrapper = styled(Box)(({ theme }) => ({
	borderRadius: 42,
	background: '#FFFFFF',
	'& .MuiFormHelperText-root': {
		textAlign: 'center',
	},
	width: '100%',
	paddingLeft: theme.spacing(3.5),
	paddingRight: theme.spacing(3.5),
	paddingTop: theme.spacing(4.5),
	// paddingBottom: theme.spacing(2),
	[theme.breakpoints.down('sm')]: {
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
		paddingTop: theme.spacing(4.5),
		// paddingBottom: theme.spacing(2),
	},
	[theme.breakpoints.up('sm')]: {
		width: 469,
	},
}));
const LeftContent = styled(Box)(({ theme }) => ({
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		width: 469,
	},
	'& > div': {
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 469,
		},
	},
}));
const RightContent = styled(Box)(({ theme }) => ({
	width: '100%',
}));
const PinkBox = styled(Box)(({ theme }) => ({
	background: theme.palette.warning.light,
	borderRadius: 30,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	flexWrap: 'nowrap',
}));
const BackButton = styled(Button)(({ theme }) => ({
	background: theme.palette.primary.light,
	':hover': {
		background: theme.palette.primary.lighter,
	},
}));
const LinkWrapper = styled(Text)(({ theme }) => ({
	fontFamily: theme.fontFamily.chalet,
	color: theme.palette.info.main,
	'& a': {
		color: theme.palette.info.main,
	},
}));
const A = styled('a')(({ theme }) => ({
	cursor: 'pointer',
}));
const LinkBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	'& a': {
		padding: theme.spacing(1),
	},
}));
const LinkText = styled(Text)(({ theme }) => ({
	fontFamily: theme.fontFamily.chalet,
	'& a': {
		textDecoration: 'none',
	},
}));
const LoginCheck: React.FC<LoginCheckProps> = ({ redirectUrl, ...props }) => {
	const { push } = useRouter();
	const { addError, addSuccess } = useStatus();
	const client = useApolloClient();
	const { back } = useRouter();
	const isMobile = React.useContext(MobileContext);
	const [exists, setExists] = React.useState(false);
	// const handleFacebookLogin = React.useCallback(() => {
	// 	onSocialLogin('FACEBOOK');
	// }, [onSocialLogin]);

	const handleback = React.useCallback(() => {
		back();
	}, [back]);
	const handleSubmit = React.useCallback(
		(email: string, phoneNumber: string) => {
			setExists(false);
			return client
				.query({
					// fetchPolicy: 'network-only',
					query: QUERY_USER_EXISTS,
					variables: {
						email,
						phoneNumber,
					},
				})
				.then(res => {
					if (
						res.data?.user_exists?.phone ||
						res.data?.user_exists?.email
					) {
						setExists(true);
						if (typeof sessionStorage !== 'undefined') {
							sessionStorage.setItem('email', email || '');
							sessionStorage.setItem(
								'phoneNumber',
								phoneNumber || '',
							);
						}
					} else {
						if (typeof sessionStorage !== 'undefined') {
							sessionStorage.setItem('email', email || '');
							sessionStorage.setItem(
								'phoneNumber',
								phoneNumber || '',
							);
						}
						if (ongoingEventId) {
							push({
								pathname: '/eventos/[eventId]/cadastro',
								query: { eventId: ongoingEventId },
							});
						} else {
							push({
								pathname: '/auth/register/[[...redirectUrl]]',
								query: {
									redirectUrl: redirectUrl || '/',
								},
							});
						}
					}
				})
				.catch(err => {
					addError('Algo deu errado! Por favor, tente novamente');
					// throw err;
				});
		},
		[addError, addSuccess, client, redirectUrl, ongoingEventId],
	);

	return (
		<ContentWrapper pb={isMobile ? 10 : undefined}>
			<ContentBox maxWidth={1332}>
				<Background />
				<Wrapper {...props}>
					<WrapperLeft>
						<LeftContent>
							<Box p={2}>
								<Row
									justifyContent="space-between"
									alignItems="center"
								>
									<Link href="/" passHref legacyBehavior>
										<A>
											<Image
												src="/logo_semina.png"
												alt="logo"
												height={27}
												width={150}
												priority
											/>
										</A>
									</Link>

									<BackButton
										variant="text"
										color="primary"
										size="medium"
										onClick={handleback}
									>
										Voltar
									</BackButton>
								</Row>
							</Box>
							<FromWrapper>
								<HomeUpcomingEvent />
								<Text variant="h3" component="h1" gutterBottom>
									Cadastrar
								</Text>
								<LoginForm
									onSubmit={handleSubmit}
									mode="check"
								/>
								{exists ? (
									<Row justifyContent="center" pt={1}>
										<Text
											variant="h5"
											component="div"
											ml={2}
											color="error.main"
											textAlign="center"
											fontWeight="bold"
										>
											O usuário existe. Faça login.
										</Text>
									</Row>
								) : null}
								<Row justifyContent="center" pt={1}>
									<LinkText
										component="div"
										pt={5}
										pb={3}
										textAlign="center"
										gutterBottom
									>
										Já tem cadastro?{' '}
										<Link
											href={`/auth/login/${encodeURIComponent(
												redirectUrl || '/',
											)}?mode=login`}
										>
											Login
										</Link>
									</LinkText>
								</Row>
							</FromWrapper>
							<PinkBox mt={2} py={2} px={3.5}>
								<Box>
									<Scope />
								</Box>
								<Text
									variant="h5"
									component="div"
									ml={2}
									color="warning.main"
								>
									Este website é restrito a profissionais de
									medicina. Para acessá-lo, é necessário
									cadastrar-se.
								</Text>
							</PinkBox>
							<LinkWrapper
								variant="h5"
								component="div"
								mt={1}
								textAlign="center"
							>
								<p>
									Não é um profissional de medicina? Acesse
									nossos websites públicos:
								</p>
								<LinkBox>
									<p>
										<Link href="/eventos">Eventos</Link>
									</p>
									<p>
										<Link
											href="https://www.semina.com.br/"
											target="_blank"
										>
											Semina
										</Link>
									</p>
									<p>
										<Link
											href="https://www.kyclinical.com.br/"
											target="_blank"
										>
											K-Y Clinical
										</Link>
									</p>
									<p>
										<Link
											href="https://www.sounuaa.com.br/"
											target="_blank"
										>
											NUÁA
										</Link>
									</p>{' '}
									<p>
										<Link
											href="https://www.amamente.com.br/"
											target="_blank"
										>
											Amamente
										</Link>
									</p>{' '}
								</LinkBox>
							</LinkWrapper>
						</LeftContent>
					</WrapperLeft>
					{isMobile ? null : (
						<WrapperRight>
							<RightContent>
								<Image
									priority
									src="/images/MaskGroupLogin.png"
									alt="login-visual"
									fill
								/>
							</RightContent>
						</WrapperRight>
					)}
				</Wrapper>
			</ContentBox>
		</ContentWrapper>
	);
};

export default LoginCheck;
