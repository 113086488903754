export const trimStarting55 = (str: string) => {
	if (str.length > 10 && str.startsWith('55')) {
		return str.slice(2);
	}
	return str;
};

export const addPhonePrefix = (phoneNumber: string, phonePrefix = '+55') => {
	if (!phoneNumber) {
		return phoneNumber;
	}
	phoneNumber = trimStarting55(phoneNumber);
	return phoneNumber.startsWith('+')
		? phoneNumber
		: `${phonePrefix}${phoneNumber}`; // assume user is from Brazil always
};

export const removePhonePrefix = (phoneNumber: string) => {
	if (!phoneNumber) {
		return phoneNumber;
	}
	phoneNumber = trimStarting55(phoneNumber);
	return phoneNumber.startsWith('+') ? phoneNumber.slice(3) : phoneNumber; // assume user is from Brazil always
};

const phoneParse = (str: string): string | null | undefined => {
	if (!str) {
		return str;
	}
	const noNum = `${str}`.replace(/\D/g, '').replace(/^0+/, '');
	if (noNum.length === 0) {
		return null;
	}
	return removePhonePrefix(noNum);
};

export default phoneParse;
