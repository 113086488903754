import React from 'react';
import { useField } from 'react-final-form';

import TextInput, { TextInputProps } from '@ui/components/TextInput';
import Col, { ColProps, GridSize } from '@ui/components/Grid/Col';

import { FieldProps } from './types';

const TextInputField: React.FC<FieldProps<TextInputProps>> = ({
	name,
	afterSubmit,
	allowNull,
	beforeSubmit,
	data,
	defaultValue,
	format,
	formatOnBlur,
	initialValue,
	isEqual,
	multiple,
	parse,
	subscription,
	type,
	validate,
	validateFields,
	value,
	helperText,
	onChange,
	onFocus,
	onBlur,
	variant,
	...rest
}) => {
	const { input, meta } = useField(name, {
		afterSubmit,
		allowNull,
		beforeSubmit,
		data,
		defaultValue,
		format,
		formatOnBlur,
		initialValue,
		isEqual,
		multiple,
		parse: type === 'number' ? parseFloat : parse,
		subscription,
		type,
		validate,
		validateFields,
		value,
	});
	const error = Boolean((meta.touched && meta.error) || meta.submitError);
	const handleChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			input.onChange(e);
			if (onChange) {
				onChange(e);
			}
		},
		[onChange, input.onChange],
	);
	const handleBlur = React.useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => {
			input.onBlur();
			if (onBlur) {
				onBlur(e);
			}
		},
		[onBlur, input.onBlur],
	);
	const handleFocus = React.useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => {
			input.onFocus(e);
			if (onFocus) {
				onFocus(e);
			}
		},
		[onFocus, input.onFocus],
	);
	return (
		// @ts-ignore tired of this
		<TextInput
			{...rest}
			{...input}
			variant={variant}
			onChange={handleChange}
			onFocus={handleFocus}
			onBlur={handleBlur}
			error={error}
			helperText={error ? meta.error || meta.submitError : helperText}
		/>
	);
};

const ColTextInputField: React.FC<
	{
		xs?: boolean | GridSize;
		sm?: boolean | GridSize;
		md?: boolean | GridSize;
		lg?: boolean | GridSize;
		xl?: boolean | GridSize;
	} & FieldProps<TextInputProps>
> = props => {
	const { xs = 12, sm, md, lg, xl, ...rest } = props;
	return (
		<Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
			<TextInputField {...rest} />
		</Col>
	);
};

export { TextInputField as default, ColTextInputField };
